<template>
  <div class="p-shadow p-bg-white p-container" style="min-height:100%;">
    <div class="p-d-flex p-jc-between">
      <span class="p-text-responsive">CONFIRMACIÓN DE PAGO</span>
    </div>
    <div class="p-grid p-mt-3">
      <div class="p-col p-d-flex p-jc-center">
        <div class="p-d-flex p-flex-column p-jc-center p-ai-center" style="width:400px; max-width:400px;">
          <div class="p-text-center p-d-flex p-jc-center p-ai-center"
            style="height:100px; width:100px; background:#dcdae9; border-radius:50%;">
            <i style="color:#263680; font-size:30pt;" class="pi pi-check-circle"></i>
          </div>
          <h3 style="font-weight:500; color:#263680;">PAGO RECIBIDO CON ÉXITO</h3>
          <p style='font-size:13pt; text-align:justify !important;'>
            Hemos recibido su pago, en estos instantes estamos
            validando la inscripción. Puede revisar su correo
            o sus pagos realizados para verificar el comprobante.
          </p>
          <div class="botones p-col-12 p-d-flex p-jc-around p-ai-center flex-wrap">
            <Button icon="pi pi-check-circle" @click="actividad" label="Mis inscripciones"
              class="p-button-lg p-button-text"></Button>
            <Button icon="pi pi-arrow-circle-left" @click="cursos" label="Explorar cursos"
              class="p-button-lg p-button-rounded p-button-ugb"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (max-width: 709px) {
  .botones {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>

<script>
export default {
  data() {
    return {
      status: '',
      referencia: '',
      estado_credenciales: '',
    }
  },
  methods: {
    actividad() {
      this.$router.push("/actividad");
    },
    cursos() {
      this.$router.push("/cursos");
    },
  },
  mounted() {
    this.status = this.$route.query.status;
    this.referencia = this.$route.query.referencia;
    if (this.referencia == undefined || this.referencia == "") this.$router.push("/cursos");
    if (this.status == undefined || this.status == "") this.$router.push("/cursos");
  },
}
</script>
